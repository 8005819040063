
body {
  font-family: sans-serif;
  font-size: 12px;
  margin: 0;
  padding: 0;
}

.inp {
  border: 1px solid #AAA;
  font-size: 10px;
}

.btn {
  font-size: 10px;
}

a {
  text-decoration: none;
}

a.link,
a.link:visited {
  color: #999999;
}

a.selected,
a.link:hover {
  color: #444444;
}

.clear {
  clear: both;
  font-size: 1px;
  height: 0;
}

ol {
  padding-left: 20px;
}

/* Application label */

.app-name {
  margin-bottom: 5px;
  margin-right: 5px;
  max-width: 500px;
  float: left;

  .logo {
    display: table;
    margin-top: 0px;
    margin-bottom: 1px;
    background-color: #000000;
    border: 2px solid #000000;

    .nikon-title {
      background: #ffde00 none repeat scroll 0 0;
      border: 2px solid #ffde00;
      color: #000;
      float: left;
      font-family: Arial, sans-serif;
      padding: 0 10px;
    }

    .nikon-pce {
      color: #fff;
      background: #000 none repeat scroll 0 0;
      border: 0;
      float: left;
      font-family: Arial, sans-serif;
      padding: 0 10px;
    }
  }

  .sub-logo-block {
    $font-size: 13px;

    display: flex;
    align-items: center;
    font-size: $font-size;

    > :not(:last-child) {
      margin-right: 5px;
    }

    .version {
    }

    .sponsor {
      display: flex;
      align-items: center;

      .icon-sponsor {
        margin-right: 5px;
        height: $font-size;
        color: #bf3989;
        fill: currentColor;
      }
    }
  }
}

a,
a:visited {
  color: #0969da;
}

.setup {
  /*width: 370px;*/
  line-height: 16px;
  display: table;
  /*float: left;*/
  margin-top: 5px;
  margin-bottom: 5px;
}

.setup input {
  padding: 0;
  margin: 0;
}

.share {
  /*width: 120px;*/
  /*line-height: 14px;*/
  display: table;
  /*float: left;*/
  /*margin-top: 5px;
  margin-bottom: 5px;*/
}

.share button {
  background: rgba(0, 0, 0, 0) url("//s.ytimg.com/yts/imgbin/www-hitchhiker-2x-vfllJPvIE.png") no-repeat scroll -360px -135px / 573px 310px;
  height: 20px;
  width: 20px;
  border: 0;
  background-color: transparent;
}

.share input {
  width: 434px;
  font-size: 10px;
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  background-color: transparent;
}

.share .header {
  font-weight: bold;
  cursor: pointer;
}

.share .url {
  border: 1px solid #666666;
  display: inline-block;
  height: 15px;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 2px;
}

.main-content {
  position: relative;
  /*width: 1040px;*/
  height: 680px;
  margin-top: 5px;
  border: 0 solid #AAA;
}

.workspace {
  display: table;
  min-width: 968px;
  min-height: 590px;
  border: 0px solid #ff0000;
}

.custom-bar {
  display: table;
  border: 0 solid #ff0000;
  height: 64px;
  margin-bottom: 5px;
}

.panel-view {
  margin-top: -2px;
}

.panel {
  background-color: #f7f7f7;
}

.panel .header {
  background-color: #ededed;
  font-weight: bold;
  height: 24px;
  line-height: 24px;
  padding-left: 20px;
  padding-right: 5px;
}

.panel .header .sub-header {
  font-weight: normal;
}

.panel .content {
  background-color: #f7f7f7;
}

.panel-1 {
  height: 576px;
  float: left;
  margin-right: 5px;
}

.panel-2 {
  height: 576px;
  float: left;
  margin-right: 5px;
}

.panel-3 {
  float: left;
  height: 550px;
  /*padding: 5px;
  border: 1px solid #AAA;*/
}

.npc-list {
  width: 160px;
  /*height: 529px;*/ /* iPad bug */
  font-size: 11px;
  margin-bottom: 2px;
  margin-left: 5px;
  margin-right: 5px;
  border: 0;
}

.panel-help {
  clear: both;
  width: 485px;
  margin-top: 40px;
  z-index: 1;

  h3 {
    margin-bottom: 10px;
  }

  .content {
    padding: 10px;
  }
}

.panel-last {
  margin-right: 0;
}

.panel-2nd-row {
}

.inp-adjust {
  width: 30px;
}

.adj-options label {
  width: 100px;
  float: left;
}

.adjust-auto {
  width: 165px;
  float: left;
  margin-right: 10px;
/*line-height: 14px;*/
}

.curve-container {
  position: relative;
  margin-left: 12px;
  margin-bottom: -30px;
}

.curve-box {
  margin-left: 15px;
  margin-bottom: 50px;
  width: 256px;
  height: 256px;

  // disable selection
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.selected-point-container {
  height: 256px;
  width: 256px;
  position: absolute;
  top: 0;
  left: 0;
}

/* curve inputs */
.inp-container {
  text-align: right;
  border: 1px solid #444;
  background-color: #fff;
  cursor: default;
  height: 13px;
  margin-right: 3px;
  overflow: hidden;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.inp-container label {
  background-color: #444;
  color: #fff;
  display: block;
  float: left;
  font-size: 10px;
  font-weight: bold;
  line-height: 15px;
  padding-right: 2px;
  text-align: right;
  width: 58px;
}

.inp-container input {
  float: left;
  width: 20px;
  font-size: 10px;
  line-height: 12px;
  margin: 0;
  padding: 0 0 0 1px;
  background: transparent none repeat scroll 0 0;
  border: 0 none;
}

/* width for two numbers */
.inp-container .num2 {
  width: 18px;
}

.inp-container .ct {
  background-color: #fff;
}

.curve-container label {
  width: 39px;
}

.output-max-box {
  position: absolute;
  left: -7px;
  top: 0px;
}

.output-min-box {
  position: absolute;
  left: -7px;
  bottom: -2px;
}

.input-min-box {
  position: absolute;
  left: 15px;
  bottom: -16px;
}

.input-max-box {
  position: absolute;
  left: 250px;
  bottom: -16px;
  z-index: 1;
}

.gamma-container {
  position: absolute;
  left: 127px;
  bottom: -16px;
  font-family: sans;
}

.gamma-container label {
  width: 5px !important;
}

.gamma-container input {
  width: 24px;
}

.custom-image {
  float: left;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;

    .inp-image-url {
      flex-grow: 1;
      margin: 0 5px;
    }

  }
}

.custom-image-container {
  margin-bottom: 5px;
}

.inp-image-url {
  background-color: #fff;
  line-height: 16px;
}

.inp-image-url input {
  width: 100%;
  font-size: 10px;
  margin-right: 3px;

  border: 0;
  background: none;
  background-color: transparent;
}

.img-preview {

}

.use-custom-curve * {
  color: #666;
}

.adj {
  width: 300px;
}

.sel-adjust {
  font-size: 9px;
  height: 14px;
}

/* adjust options */

.adjust-container {

}

.clear-row {
  clear: both;
  font-size: 1px;
  height: 0;;
  margin-bottom: 2px;
}

.adjust-container .inp-container {
  width: 82px;
  float: left;
}

.adjust-auto {
/*position: relative;*/
}

.adjust-auto input {
  height: 13px;
  margin: 1px 0 0 2px;
/*position: absolute;*/
}

.adjust-auto label {
  margin-left: 2px;
}

.canvas-curve,
.canvas-histogram {
  position: absolute;
  width: 256px;
  height: 256px;
/*border: 1px solid #000000;*/
}

.view-opt-container {
  margin-left: 13px;
}

.view-opt-container input {
  margin-left: 0;
}

/* input 20 points */
.points-container {
  display: table;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 0px;
}

.point-container {
  cursor: default;
  float: left;
  margin-top: 2px;
  margin-right: 1px;
  height: 13px;
  border: 1px solid #444;
  background-color: #FFFFFF;
  overflow: hidden;
/*position: relative;*/
}

.point-container-selected {
  border: 1px solid #ff0000 !important;
}

.point-container-selected .num {
  background-color: #FF0000 !important;
}

.point-container-selected .spliter {
  border-left: 1px solid #FF0000 !important;
}

.point-container .spliter {
  width: 1px;
  height: 13px;
  border-left: 1px solid #444;
  /*position: absolute;*/
  display: block;
  float: left;
}

.point-container input {
  float: left;
  width: 20px;
  font-size: 10px;
  line-height: 12px;
  padding: 0;
  margin: 0;
  text-align: right;
  border: 0;
  background: none;
  background-color: transparent;
}

.point-container .num {
  padding-right: 2px;
  width: 13px;
  text-align: right;
  display: block;
  float: left;
  line-height: 15px;
  color: #FFF;
  font-size: 10px;
  font-weight: bold;
  background-color: #444;
}

.point-container .x {
}

.point-container .y {
}

.curve-content {
  position: relative;
  border: 1px solid #000;
  width: 256px;
  height: 256px;
}

.selected-point {
  cursor: default;
  position: absolute;
  width: 6px;
  height: 6px;
  border: 2px solid #ff0000;
  border-radius: 5px;
  /*visibility: hidden;*/
  z-index: 10;
}

#pointKey {
  position: absolute;
  left: -1px;
  top: -1px;
  width: 1px;
  height: 1px;
  line-height: 1px;
  font-size: 1px;
  outline: 0 none;
  -moz-outline: 0 none;
  -moz-user-select: text;
  -khtml-user-select: text;
}

#pointKey,
#pointKey:hover,
#pointKey:focus,
#pointKey:active {
  text-decoration: none;
}

.download-modefied {
  clear: both;
  font-size: 12px;
  margin-top: 5px;
}

.download-modefied .btn {
  margin-top: 3px;
}

.download-modefied label {

}

.download-modefied .inp-container {
  width: 295px;
}

.download-modefied .inp-text {
  width: 230px;
}

.original-preview {
  z-index: 10;
  position: absolute;
}

.adsbygoogle {
  float: left;
}

.invisible {
  // override display if it was as table to set width/height
  display: block;
  position: absolute;
  opacity: 0;
  width: 1px;
  height: 1px;
  z-index: -1;
}

.disqus-thread {
  display: none;
}

/* preview list */
.preview-list-box {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

.preview-list-box .list {
  display: inline-block;
  width: 968px;
}

.preview-list-box img {
  height: 48px;
  margin-right: 1px;
  cursor: pointer;
}

.paypal-logo {
  display: inline-block;
  width: 122px;
  height: 29px;
  margin: 6px 0;
  margin-top: 5px;

  background-image: url("https://www.paypalobjects.com/webstatic/paypalme/images/pp_logo_small.png");
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 0;
  background-size: cover, 100%;
}

// cookie banner
.fucking-eu-parlament-gdpr {
  //display: flex;
  display: none;
  flex-direction: row;
  text-align: left;
  align-items: center;
  background: #f4f6f5;
  border-radius: .8rem;
  bottom: 1.5rem;
  box-shadow: 0 4px 15px 2px rgba(0,0,0,.1);
  justify-content: center;
  max-width: 66rem;
  position: fixed;
  right: 1rem;
  z-index: 99;

  .btn.accept-cookie-banner {
    background: #465a69;
    border-radius: 5rem;
    color: #fff;
    margin-right: 1rem;
    width: 9rem;
  }
}

// place in the div all ads
#ads-jail {
  display:none !important;
  width:1px !important;
  height:1px !important;
  overflow: hidden !important;
}
